import { useAppContext } from '../context/app';
import {
  useCleanFileNode,
  useCreateFolderFileNode,
  useDeleteFileNode,
  useDownloadFileNode,
  useFileNodeGeolocationUpdate,
  useFileNodeNavigationOptionsUpdate,
  useFilenodeStructure,
  useForgetFileNode,
  useGenerateFileNodes,
  useMoveFileNode,
  usePrepareFileNode,
  useProcessFileNode,
  useRootStructure,
} from '../hooks/data_delivery';
import { FileNode, FileNodeStatus } from '../types/DataDelivery';

const dataDeliveryActions = (orgId: string) => {
  const { dispatch } = useAppContext();
  const createFolderFileNodeMutation = useCreateFolderFileNode(orgId);
  const prepareFileNodeMutation = usePrepareFileNode();
  const processFileNodeMutation = useProcessFileNode();
  const updateFileNodeGeolocationMutation = useFileNodeGeolocationUpdate();
  const updateFileNodeNavigationOptionsMutation = useFileNodeNavigationOptionsUpdate();
  const generateFileNodesMutation = useGenerateFileNodes();
  const moveFileNodeMutation = useMoveFileNode(orgId);
  const cleanFileNodeMutation = useCleanFileNode(orgId);
  const forgetFileNodeMutation = useForgetFileNode(orgId);
  const deleteFileNodeMutation = useDeleteFileNode(orgId);
  const downloadFileNodeMutation = useDownloadFileNode();

  const handleCreateFolderFileNode = async (name: string, parent: FileNode) => {
    return await createFolderFileNodeMutation.mutateAsync({ name, parentId: parent?.id, rootId: parent?.rootId });
  };

  const handleDownloadFileNode = (fileNode: FileNode) => {
    downloadFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, fileNodeName: fileNode.name });
  };

  const handlePrepareFileNode = (fileNode: FileNode) => {
    prepareFileNodeMutation.mutateAsync(fileNode?.path).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Preparing ' + fileNode.name + ' for Visualization.', type: 'success' } },
      });
    });
  };

  const handleProcessFileNode = (fileNode: FileNode) => {
    /*if (fileNode?.status !== FileNodeStatus.CREATED) {
      //setConfirmingReprocessFilenode(fileNode);
    } else*/ {
      processFileNodeMutation.mutateAsync({ fileNodeId: fileNode?.id, force: false }).then(() => {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { content: 'Started FileNode Processing', type: 'success' } },
        });
      });
    }
  };

  const handleUpdateFileNode = (fileNode: FileNode, onSuccess?: ()=>void, onError?: ()=>void) => {
    generateFileNodesMutation.mutateAsync(fileNode?.path, {onSuccess, onError}).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Update FileNode ' + fileNode.name + ' structure.', type: 'success' } },
      });
    });
    return {
      isLoading: generateFileNodesMutation.isLoading,
      isSuccess: generateFileNodesMutation.isSuccess,
    };
  };

  const handleUpdateFileNodeGeolocation = (fileNode: FileNode) => {
    updateFileNodeGeolocationMutation.mutateAsync({ fileNodeId: fileNode?.id }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Updated FileNode ' + fileNode.name + ' geolocation.', type: 'success' } },
      });
    });
  };

  const handleCleanFileNode = (fileNode: FileNode) => {
    cleanFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, rootId: fileNode.rootId }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Cleaned FileNode ' + fileNode.name + '.', type: 'success' } },
      });
    });
  };
  const handleDeleteFileNode = (fileNode: FileNode) => {
    deleteFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, rootId: fileNode.rootId });
  };
  const handleForgetFileNode = (fileNode: FileNode) => {
    forgetFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, rootId: fileNode.rootId }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Forgot FileNode ' + fileNode.name + '.', type: 'success' } },
      });
    });
  };
  const handleMoveFileNode = (fileNode: FileNode, newParent: FileNode) => {
    moveFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, newParent: newParent.id, rootId: fileNode.rootId, newRootId: newParent.rootId }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Moved File ' + fileNode.name + '.', type: 'success' } },
      });
    });
    return {
      isLoading: moveFileNodeMutation.isLoading,
      isSuccess: moveFileNodeMutation.isSuccess,
    };
  };

  return {
    handleCreateFolderFileNode,
    handlePrepareFileNode,
    handleDownloadFileNode,
    handleProcessFileNode,
    handleUpdateFileNode,
    handleUpdateFileNodeGeolocation,
    handleCleanFileNode,
    handleDeleteFileNode,
    handleForgetFileNode,
    handleMoveFileNode,
    movingFileNode: moveFileNodeMutation.isLoading,
    movedFileNode: moveFileNodeMutation.isSuccess,
  };
};

export default dataDeliveryActions;
