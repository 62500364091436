import React, { useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { shareIcon } from '@progress/kendo-svg-icons';
import { Dialog as ASD, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SubmitButton from './SubmitButton';
import Dialog from '../Dialog';
import CopyableTextBox from './CopyableTextBox';
import { useShareFileNode, useShareTransaction } from '../../hooks/user_management';
import { useUser } from '../../hooks/authentication';
import IconButton from './IconButton';
import { useAppContext } from '../../context/app';

interface Props {
  resourceName: string;
  fileNodeId?: string;
  transactionId?: string;
  disabled?: boolean;
  style?: any;
  iconStyle?: any;
  iconHoveredStyle?: any;
}

const ShareResourceButton: React.FC<Props> = ({
  style,
  resourceName,
  fileNodeId,
  transactionId,
  disabled,
  iconStyle,
  iconHoveredStyle,
}) => {
  const { dispatch } = useAppContext();
  const [hovered, setHovered] = useState(false);
  const [sharing, setSharing] = useState<boolean>(false);
  const { getCurrentOrganization, getOrgSubscriptionValid } = useUser();
  const shareFileNodeMutation = useShareFileNode();
  const shareTransactionMutation = useShareTransaction();

  const handleShare = (e: any) => {
    e.preventDefault();
    if (!getOrgSubscriptionValid()) {
      dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
      return true;
    }
    if (!disabled) {
      if (fileNodeId) {
        shareFileNodeMutation.mutate(
          { filenode_id: fileNodeId },
          {
            onSuccess: () => {
              setSharing(true);
            },
          }
        );
      } else if (transactionId) {
        shareTransactionMutation.mutate(
          { transaction_id: transactionId },
          {
            onSuccess: () => {
              setSharing(true);
            },
          }
        );
      } else {
        console.error('Nothing to share.');
      }
    }
  };

  const containerStyle = { ...{ display: 'flex', justifyContent: 'center', alignItems: 'center' }, ...style };

  if (!getCurrentOrganization().sharingEnabled) {
    return null;
  }

  return (
    <div style={containerStyle}>
      {shareFileNodeMutation.isLoading || shareTransactionMutation.isLoading ? (
        <Loader size="small" type={'converging-spinner'} themeColor={'light'} />
      ) : (
        <IconButton
          icon={shareIcon}
          style={iconStyle}
          hoveredStyle={iconHoveredStyle}
          tooltip={'Click to obtain a sharing link for this page.'}
          onClick={handleShare}
        ></IconButton>
      )}
      <Dialog
        show={sharing}
        title={'Share '}
        onClose={() => {
          setSharing(false);
        }}
        style={{ width: '600px', position: 'absolute' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '1rem' }}>
          <p
            style={{
              margin: '25px',
              textAlign: 'center',
            }}
          >
            Copy the following link to share the current data with outside viewers.
          </p>
          {shareFileNodeMutation.isSuccess && (
            <CopyableTextBox
              text={window.location.origin + '/shared/' + shareFileNodeMutation.data?.id}
            ></CopyableTextBox>
          )}
          {shareTransactionMutation.isSuccess && (
            <CopyableTextBox
              text={window.location.origin + '/shared/' + shareTransactionMutation.data?.id}
            ></CopyableTextBox>
          )}
        </div>
        <DialogActionsBar>
          <SubmitButton
            label="OK"
            themeColor="primary"
            onClick={() => {
              setSharing(false);
            }}
          />
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};

export default ShareResourceButton;
