import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { FileNode, FileNodeViewType } from '../../types/DataDelivery';
import { findFileNodeRecursively, mergeFileNodes } from '../../common/fileNodeHelper';

interface Params {
    fileNodeId: string;
    newName: string;
}

const useFileNodeUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<FileNode>("filenode/" + params.fileNodeId + "/rename", params);
      return response.data;
    },
    {
      onSuccess: (data) => {
        const rootFileNode: FileNode = queryClient.getQueryData(['rootStructure', data.organizationId]);
        if (rootFileNode) {
          const fileNodeToUpdate: FileNode = findFileNodeRecursively(rootFileNode, data.id);
          if (fileNodeToUpdate) {
            const mergedFileNode = mergeFileNodes(fileNodeToUpdate, data);
            Object.assign(fileNodeToUpdate, mergedFileNode);
            queryClient.setQueryData(['rootStructure', data.organizationId], rootFileNode);
          }
        }
        if (queryClient.getQueryData(['fileNodeStructure', data.rootId])) {
          const rootFileNode2: FileNode = JSON.parse(
            JSON.stringify(queryClient.getQueryData(['fileNodeStructure', data.rootId]))
          );
          if (rootFileNode2) {
            const fileNodeToUpdate: FileNode = findFileNodeRecursively(rootFileNode2, data.id);
            if (fileNodeToUpdate) {
              const mergedFileNode = mergeFileNodes(fileNodeToUpdate, data);
              Object.assign(fileNodeToUpdate, mergedFileNode);
              queryClient.setQueryData(['fileNodeStructure', data.rootId], rootFileNode2);
            }
          }
        }
        // Also go through all of this FileNode's children recursively to invalidate any potential ViewerConfig as URL might have changed.
        const recursiveChildrenViewerConfigInvalidation = (fileNode: FileNode) => {
          if(queryClient.getQueryData(['fileNodeView', fileNode.id])) {
            queryClient.invalidateQueries(['fileNodeView', fileNode.id], { refetchInactive: true });
          }
          if(fileNode.children && fileNode.children.length > 0) {
            fileNode.children.forEach((childFileNode: FileNode)=>{
              recursiveChildrenViewerConfigInvalidation(childFileNode);
            });
          }
        }
        recursiveChildrenViewerConfigInvalidation(data);
      },
    }
  );
};

export default useFileNodeUpdate;
