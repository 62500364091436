import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
  rootId: string;
  showSupport: boolean;
  baseMapId: string;
}

const useFileNodeNavigationOptionsUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post('filenode/' + params.rootId + '/options', params);
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['fileNodeStructure', variables.rootId], { refetchInactive: true });
      },
    }
  );
};

export default useFileNodeNavigationOptionsUpdate;
