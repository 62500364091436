import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { useState } from 'react';
import { FileNode } from '../../types/DataDelivery';

interface UploadParams {
  files: any,
  parentId: string,
  rootId: string,
}

const useUploadFileNode = (orgId: string) => {
  const [ progress, setProgress ] = useState(0);
  const [ total, setTotal ] = useState(0);
  const [ loaded, setLoaded ] = useState(0);
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (params: UploadParams) => {
      const formData: FormData = new FormData();
      formData.append("files", params.files);
      formData.append("parentId", params.parentId);
      const response = await axios.post<FileNode>("filenode/upload", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setLoaded(progressEvent.loaded);
          setTotal(progressEvent.total);
          setProgress(percentCompleted);
        }
      },

    );
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['rootStructure', orgId], { refetchInactive: true });
        if(variables.rootId) {
          queryClient.invalidateQueries(['fileNodeStructure', variables.rootId], { refetchInactive: true });
        }
      },
    }
  );
  return {...mutation, progress, loaded, total}
};

export default useUploadFileNode;