import { QueryClient } from 'react-query';
import {
    FileNodeStatusNotification,
    WSMessage,
  } from '../../../types';
import { FileNode } from '../../../types/DataDelivery';
import { findFileNodeRecursively } from '../../../common/fileNodeHelper';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
    const fileNodeNotif: FileNodeStatusNotification = wsMessage.payload;
    const rootFileNode: FileNode = queryClient.getQueryData(['rootStructure', wsMessage.payload.orgId]);
    if (rootFileNode) {
      const fileNodeToUpdate: FileNode = findFileNodeRecursively(rootFileNode, fileNodeNotif.fileNodeId);
      if (fileNodeToUpdate) {
        fileNodeToUpdate.status = fileNodeNotif.status;
        fileNodeToUpdate.errorMessage = fileNodeNotif.errorMessage;
        fileNodeToUpdate.geolocation = fileNodeNotif.geolocation;
        fileNodeToUpdate.viewLayer = fileNodeNotif.viewLayer;
        queryClient.setQueryData(['rootStructure', wsMessage.payload.orgId], rootFileNode);
      }
    }
    if (queryClient.getQueryData(['fileNodeStructure', fileNodeNotif.rootFileNodeId])) {
      const rootFileNode2: FileNode = JSON.parse(
        JSON.stringify(queryClient.getQueryData(['fileNodeStructure', fileNodeNotif.rootFileNodeId]))
      );
      if (rootFileNode2) {
        const fileNodeToUpdate: FileNode = findFileNodeRecursively(rootFileNode2, fileNodeNotif.fileNodeId);
        if (fileNodeToUpdate) {
          fileNodeToUpdate.status = fileNodeNotif.status;
          fileNodeToUpdate.errorMessage = fileNodeNotif.errorMessage;
          fileNodeToUpdate.geolocation = fileNodeNotif.geolocation;
          fileNodeToUpdate.viewLayer = fileNodeNotif.viewLayer;
          queryClient.setQueryData(['fileNodeStructure', fileNodeNotif.rootFileNodeId], rootFileNode2);
        }
      }
    }
}

export default handleMessage;